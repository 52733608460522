<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Por favor, seleccione los requisitos que desea evaluar:</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(nuevaEvaluacion)">
                            <b-row>
                                <b-col md="12">
                                    <b-button v-if="!BtnMostrar" variant="dark" class="float-right mr-2" size="sm" @click="selectAllRows"><i class="fas fa-check-square"></i> Seleccionar todos</b-button>
                                    <b-button v-else-if="BtnMostrar" variant="dark" class="float-right mr-2" size="sm" @click="clearSelected"><i class="far fa-square"></i> Limpiar selección</b-button>
                                </b-col>
                                <b-col md="12" class="mt-3">
                                    <b-table :items="listaRequisitos" :fields="cabecera" ref="selectableTable" selectable @row-selected="onRowSelected" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template #cell(selected)="{ rowSelected }">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                                <span class="sr-only">Seleccionado</span>
                                            </template>
                                            <template v-else>
                                                <span aria-hidden="true">&nbsp;</span>
                                                <span class="sr-only">No seleccionado</span>
                                            </template>
                                        </template>
                                        <template #cell(opciones)="param">
                                            <b-button v-if="param.item.idEvaluacionDetalleRequisito!=''" @click="eliminarRequisitoDetalle(param)" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                               <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <!-- {{arrayRequisitos}} -->
                                </b-col>
                            </b-row>

                            <b-col class="my-2 text-center" md="12">
                                <b-button class="mr-2" :to="{name: 'Requisitos medioambientales 14001'}" size="md" variant="dark">
                                    <i class="fas fa-arrow-left"></i> Volver
                                </b-button>
                                <b-button v-if="$route.params.id!=null" v-show="arrayRequisitos.length>0" :to="{name: 'Gestion evaluacion requisitos 14001', params: {id: $route.params.id, arrayRequisitos: arrayRequisitos}}" size="md" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>
                                <b-button v-if="$route.params.id==null" v-show="arrayRequisitos.length>0" :to="{name: 'Gestion evaluacion requisitos 14001', params: {arrayRequisitos: arrayRequisitos}}" size="md" variant="success" class=" mr-2">
                                    <i class="fas fa-arrow-right"></i> Continuar
                                </b-button>

                            </b-col>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            BtnMostrar: false,
            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "selected",
                    label: "Selección",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            listaRequisitos: [],
            arrayRequisitos: [],
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onRowSelected(items) {
            this.arrayRequisitos = items;
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
            this.BtnMostrar = !this.BtnMostrar;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
            this.BtnMostrar = !this.BtnMostrar;
        },
        listarRequisitos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-requisitos-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    //me.listaRequisitos = response.data
                    for (const i in response.data) {
                        me.listaRequisitos.push({
                            idEvaluacionDetalleRequisito: '',
                            idEvaluacionRequisito: '',
                            idRequisito: response.data[i].idRequisito,
                            nombre: response.data[i].nombre,
                            metodologiaEvaluacion: response.data[i].metodologiaEvaluacion,
                            evaluacionCumplimiento: 1,
                            observaciones: '',
                        });
                    }
                    if (me.$route.params.id != null) {
                        me.consultarEvaluacionRequisito();
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarEvaluacionRequisito() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/consultar-evaluacion-requisito", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    for (const i in response.data) {
                        for (const j in me.listaRequisitos) {
                            if (me.listaRequisitos[j].idRequisito == response.data[i].idRequisito) {
                                me.listaRequisitos[j].idEvaluacionDetalleRequisito = response.data[i].idEvaluacionDetalleRequisito;
                                me.$refs.selectableTable.selectRow(parseInt(j))
                            }
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarRequisitoDetalle(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el requisito?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-detalle-requisito-sgma", {
                            idEvaluacionDetalleRequisito: param.item.idEvaluacionDetalleRequisito,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            //me.listarRiesgos();
                            me.listaRequisitos[param.index].idEvaluacionDetalleRequisito = '';
                            //me.$refs.selectableTable.selectRow(parseInt(j))
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarRequisitos();
        }
    }

}
</script>
